import './App.css';
import HomePage from "./page/home/home.page";
import axios from "axios";
import NotificationComponent from "./components/notification/notification.component";
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom";
import RootLayoutPage from "./page/root-layout/root-layout.page";
import ErrorPage from "./page/error/error.page";
import PrivateAdminRoute from "./tools/PrivateAdminRoute";
import DashboardPage from "./page/admin/dashbord/dashboard.page.";
import LoginPage from "./page/admin/login/login.page";
import LoginGuardPage from "./components/login-guard/login-guard.page";
import PrivateRoute from "./tools/PrivateRoute";
import CompetitionDetailPage from "./page/competition-detail/competition-detail.page";
import SecondStepPage from "./page/subscriptions/new/second-step.page";
import FirstStepGuard from "./page/subscriptions/new/FirstStepGuard";
import FirstStepPage from './page/subscriptions/new/first-step.page';
import Subscriptions from "./page/subscriptions/list/subscriptions";
import EditSchoolPage from "./page/school/edit-school.page";
import EditSchoolInfoPage from "./page/user/edit-user.page";
import ChangePasswordPage from "./page/change-password/change-password.page";
import ResetPasswordRequestPage from "./page/admin/reset-password-request/reset-password-request.page";
import ResetPasswordPage from "./page/admin/reset-password/reset-password.page";
import ActiveEventRoute from "./tools/ActiveEventRoute";

const App = () => {
    axios.defaults.withCredentials = true;

    const router = createBrowserRouter(createRoutesFromElements(
        <>
            <Route path='/' element={<RootLayoutPage/>} errorElement={<ErrorPage/>}>
                <Route path="/" element={<HomePage/>}/>
                <Route path="home" element={<HomePage/>}/>
                <Route path="login" element={<LoginGuardPage/>}/>
                <Route path="reset-password-request" element={<ResetPasswordRequestPage/>}/>
                <Route path="reset-password" element={<ResetPasswordPage/>}/>
                <Route element={<PrivateRoute/>}>
                    <Route path="change-password" element={<ChangePasswordPage/>}/>
                    <Route path="school" element={<EditSchoolPage/>}/>
                    <Route path="school-info" element={<EditSchoolInfoPage/>}/>
                    <Route path="event/:eventId">
                        <Route index={true} element={<CompetitionDetailPage/>}/>
                        <Route element={<ActiveEventRoute/>}>
                            <Route path="subscription">
                                <Route path="first-step" element={
                                    <FirstStepGuard><FirstStepPage/></FirstStepGuard>}/>
                                <Route path="second-step" element={<SecondStepPage/>}/>
                            </Route>
                        </Route>
                    </Route>
                    <Route path="subscriptions" element={<Subscriptions/>}/>
                </Route>
                <Route path="admin/1a9e8dde1c3e769701aa1feb80754b43ae6eb2e48a952f663f3452b2ad19430c">
                    <Route element={<PrivateAdminRoute/>}>
                        <Route index={true} element={<DashboardPage/>}/>
                        <Route path="dashboard" element={<DashboardPage/>}/>
                    </Route>
                    <Route path="login" element={<LoginPage/>}/>
                </Route>
            </Route>
        </>
    ))

    return (
        <>
            <NotificationComponent/>
            <RouterProvider router={router}/>
        </>

    );
}

export default App