import {configureStore} from "@reduxjs/toolkit";
import {loadLoginFromLocalStorage, loginReducer} from "./login.state";
import {notificationReducer} from "./notification.state";
import {loadSchoolFromLocalStorage, schoolReducer} from "./school.state";
import {registrationErrorModalReducer} from "./registration-error-modal.state";


const store = configureStore({
    reducer: {
        login: loginReducer,
        notification: notificationReducer,
        school: schoolReducer,
        registrationErrorModal: registrationErrorModalReducer
    },
    preloadedState: {
        login: loadLoginFromLocalStorage(),
        school: loadSchoolFromLocalStorage()
    }
})

export type RootState = ReturnType<typeof store.getState>

export default store