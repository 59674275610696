import {Navigate, Outlet, useLocation, useParams} from 'react-router-dom'
import * as React from "react"
import {useEffect, useState} from "react"
import {IEvent} from "../model/event.model"
import API from "../api"
import {AxiosResponse} from "axios"

const ActiveEventRoute = () => {
    const location = useLocation()
    const params = useParams()
    const [isEventActive, setEventActive] = useState<boolean>(false)
    const [isLoading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        if (!params.eventId) {
            setLoading(false)
            return
        }

        API.get("event", {
            params: {
                eventId: params.eventId,
            }
        }).then((response: AxiosResponse<IEvent>) => {
            setEventActive(response.data.active)
        }).finally(() => {
            setLoading(false)
        })
    }, [params.eventId])

    if (isLoading) {
        return <div>Caricamento...</div>
    }

    return isEventActive ? <Outlet/> : <Navigate to={{ pathname: "/" }} replace state={{ from: location }} />
}

export default ActiveEventRoute