import {Button, Card, Col, Form, Input, Row, Select} from "antd"
import React, {useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom"

import './common-step.css'
import CompetitorComponent from "../../../components/competitor/competitor-component"
import {PlusOutlined} from "@ant-design/icons"
import {ICompetitor} from "../../../model/competitor.model"
import API from "../../../api"
import {DisciplineType, disciplineTypeData, IDiscipline} from "../../../model/discipline.model"
import {IClass} from "../../../model/class.model"
import {ICategory} from "../../../model/category.model"

import {from} from 'rxjs'
import {pipe} from 'fp-ts/function'
import * as OBS from 'fp-ts-rxjs/Observable'
import * as RA from 'fp-ts/ReadonlyArray'
import * as O from 'fp-ts/Option'
import {IEvent} from "../../../model/event.model"
import {sequenceS} from "fp-ts/Apply"
import {SubscriptionCreateRequest} from "../../../model/subscription.model"
import {useDispatch, useSelector} from "react-redux"
import {RootState} from "../../../store"
import {notificationActions} from "../../../store/notification.state"
import {formatterDate} from "../../../util/date.formatter";
import {stringify} from "qs";
import {exportSchoolSubscriptions} from "../../user/export.service";

export interface SecondStepData {
    readonly groupName?: string
    readonly choreographyName?: string
    readonly competitors: ICompetitor[]
    readonly categoryId?: number
    readonly classId?: string
    readonly disciplineId?: number
}

const SecondStepPage = () => {
    const [currentType, setCurrentType] = useState<DisciplineType | undefined>(undefined)
    const [disciplines, setDisciplines] = useState([] as ReadonlyArray<IDiscipline>)
    const [classes, setClasses] = useState([] as ReadonlyArray<IClass>)
    const [categories, setCategories] = useState([] as ReadonlyArray<ICategory>)
    const [event, setEvent] = useState<IEvent>({} as IEvent)
    const [currentDiscipline, setCurrentDiscipline] = useState<number | undefined>(undefined)

    const navigate = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()

    const school = useSelector((state: RootState) => state.school)

    const [form] = Form.useForm()

    useEffect(() => {
        API.get("event", {
            params: {
                eventId: params.eventId
            }
        }).then(result => {
            setEvent(result.data)
        })
    }, [params.eventId])

    useEffect(() => {
        if (currentType) {
            API.get("discipline", {
                params: {
                    eventId: params.eventId,
                    disciplineType: currentType === 3 ? [3, 4, 5] : [currentType]
                },
                paramsSerializer: params => {
                    return stringify(params)
                }
            })
                .then(result => {
                    setDisciplines(result.data)
                    setCategories([])
                    setClasses([])
                })

            form.resetFields()
            form.setFieldValue("competitors", Array.from({length: currentType}).map(() => ({
                surname: "",
                fiscalCode: "",
                name: ""
            })))
        }
    }, [currentType])

    useEffect(() => {
        if (currentDiscipline) {
            const classesOBS = from(API.get("class", {
                params: {
                    eventId: params.eventId,
                    disciplineId: currentDiscipline
                }
            }))

            const categoriesOBS = from(API.get("category", {
                params: {
                    eventId: params.eventId,
                    disciplineId: currentDiscipline
                }
            }))

            pipe(
                {
                    classes: classesOBS,
                    categories: categoriesOBS
                },
                sequenceS(OBS.Monad)
            ).subscribe(result => {
                setClasses(result.classes.data ?? [])
                setCategories(result.categories.data ?? [])
            })
        }
    }, [currentDiscipline])

    const save = (data: SecondStepData) => {
        const request: SubscriptionCreateRequest = {
            competitors: data.competitors,
            groupName: data.groupName,
            choreographyName: data.choreographyName,
            categoryId: data.categoryId!,
            classId: data.classId!,
            disciplineId: data.disciplineId!,
            eventId: event.id,
            type: pipe(
                disciplines,
                RA.findFirst(discipline => discipline.id === data.disciplineId!),
                O.map(discipline => discipline.disciplineType),
                O.toUndefined
            )!
        }

        API.post("subscription", request, {
            params: {
                schoolId: school.id
            }
        }).then(() => {
            dispatch(notificationActions.success("Iscrizione avvenuta con successo"))
            form.setFieldValue("disciplineId", undefined)
            form.setFieldValue("classId", undefined)
            form.setFieldValue("categoryId", undefined)
            form.setFieldValue("groupName", undefined)
            form.setFieldValue("choreographyName", undefined)
        })
    }

    const handleDiscipline = (discipline: number) => {
        form.setFieldValue("classId", undefined)
        form.setFieldValue("categoryId", undefined)
        setCurrentDiscipline(discipline)
    }

    const addDancer = () => {
        const competitors: ReadonlyArray<ICompetitor> = form.getFieldValue('competitors')
        form.setFieldValue("competitors", [
            ...competitors,
            {
                surname: "",
                fiscalCode: "",
                name: ""
            }
        ])
    }

    const removeDancer = (index: number) => {
        const competitors: ReadonlyArray<ICompetitor> = form.getFieldValue('competitors')
        form.setFieldValue("competitors", RA.unsafeDeleteAt(index, competitors))
    }

    const isCurrentType = (type: DisciplineType) => {
        return currentType === type
    }

    const backToHome = () => {
        navigate('/')
    }

    return (
        <>
            <h1 style={{textAlign: "center", fontSize: "30px", marginBottom: "30px"}}>{formatterDate(event.date)} - {event.description}</h1>
            <h2>Scegli la tipologia di iscrizione</h2>
            <Row gutter={[100, 20]} justify="space-between">
                {disciplineTypeData.map(value => (
                    <Col xs={24} lg={8} key={value.type}>
                        <Card
                            style={{textAlign: "center"}}
                            onClick={() => setCurrentType(value.type)}
                            hoverable={!isCurrentType(value.type)}
                            className={`${isCurrentType(value.type) ? "active" : ""}`}
                        >
                            <h2>{value.label}</h2>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Form
                form={form}
                layout="vertical"
                size="middle"
                name="dancerForm"
                onFinish={save}
            >
                {currentType && (
                    <>
                        <h2 style={{marginTop: '40px'}}>Scegli la gara</h2>
                        <Row gutter={100} justify="space-between">
                            <Col xs={24} lg={8}>
                                {disciplines &&
                                    <Form.Item
                                        label="Disciplina"
                                        key={"disciplineId"}
                                        name="disciplineId"
                                        rules={[{required: true, message: 'Disciplina obbligatoria'}]}
                                    >
                                        <Select
                                            disabled={disciplines.length === 0}
                                            placeholder="Seleziona una disciplina"
                                            options={disciplines.map((item) => ({
                                                label: item.description,
                                                value: item.id
                                            }))}
                                            onChange={(value) => handleDiscipline(value)}
                                        />
                                    </Form.Item>
                                }
                            </Col>
                            <Col xs={24} lg={8}>
                                {categories &&
                                    <Form.Item
                                        label="Categoria"
                                        key={"categoryId"}
                                        name="categoryId"
                                        rules={[{required: true, message: 'Categoria obbligatoria'}]}
                                    >
                                        <Select
                                            disabled={categories.length === 0}
                                            placeholder="Seleziona una categoria"
                                            options={categories.map((item) => ({
                                                label: item.description,
                                                value: item.id
                                            }))}
                                        />
                                    </Form.Item>
                                }
                            </Col>
                            <Col xs={24} lg={8}>
                                {classes &&
                                    <Form.Item
                                        label="Classe"
                                        key={"classId"}
                                        name="classId"
                                        rules={[{required: true, message: 'Classe obbligatoria'}]}
                                    >
                                        <Select
                                            disabled={classes.length === 0}
                                            placeholder="Seleziona una classe"
                                            options={classes.map((item) => ({
                                                label: item.description,
                                                value: item.code
                                            }))}
                                        />
                                    </Form.Item>
                                }
                            </Col>
                        </Row>
                        <h2 style={{marginTop: '40px'}}>Inserisci i dati degli atleti</h2>
                        {currentType === 3 ? (
                            <>
                                <Form.Item
                                    label="Nome Gruppo - Nome Coreografia (aggiungere sia il Nome del Gruppo sia il Nome della coreografia)"
                                    name="groupName"
                                    key="groupName"
                                    rules={[{required: true, message: 'Nome gruppo obbligatorio'}]}
                                >
                                    <Input/>
                                </Form.Item>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: '20px'
                                }}>
                                    <Button onClick={addDancer}><PlusOutlined style={{marginRight: '5px'}}/>
                                        Aggiungi atleta
                                    </Button>
                                </div>
                            </>
                        ) : <>
                            <Form.Item
                                label="Nome Coreografia (Non obbligatoria)"
                                name="choreographyName"
                                key="choreographyName">
                                <Input/>
                            </Form.Item>
                        </>
                        }
                        <Row gutter={[16, 24]} align="middle">
                            <Form.List name='competitors'>
                                {(fields) => {
                                    return (
                                        fields.map(({key, name, ...restField}) => (
                                            <Col key={key} xs={24} lg={8}>
                                                <div style={{
                                                    border: '1px solid lightgrey',
                                                    padding: '5px',
                                                    borderRadius: '5px'
                                                }}>
                                                    <CompetitorComponent
                                                        id={name}
                                                        bmillesimo={event.bmillesimo}
                                                        restField={restField}
                                                        form={form}
                                                    ></CompetitorComponent>
                                                    {currentType === 3 && key > 2 && (
                                                        <div
                                                            style={{display: 'flex', justifyContent: 'center'}}>
                                                            <Button
                                                                onClick={() => removeDancer(key)}>Rimuovi</Button>
                                                        </div>
                                                    )}
                                                </div>
                                            </Col>
                                        ))
                                    )
                                }
                                }
                            </Form.List>
                        </Row>
                    </>
                )}

                <div className="step buttons-container">
                    <Button type="default" onClick={backToHome}>
                        Torna alla home
                    </Button>
                    {currentType && (
                        <>
                            <Button type="primary" htmlType="submit">
                                Iscrivi
                            </Button>
                        </>
                    )}
                    <Button type="primary"
                            onClick={() => exportSchoolSubscriptions(params.eventId!, school, event.description)}>
                        Scarica il riepilogo delle tue iscrizioni
                    </Button>
                </div>

            </Form>
        </>
    )
}

export default SecondStepPage
