import {Button, Checkbox, Col, Form, Input, Row} from "antd"
import React, {useState} from "react"
import {useDispatch} from "react-redux"
import API from "../../api"
import {loginActions} from "../../store/login.state"
import {useLocation, useNavigate, useSearchParams} from "react-router-dom"
import './login-guard.page.css'
import {AxiosResponse} from "axios"
import {IUser} from "../../model/user.model"
import {ISchool} from "../../model/school.model"
import {schoolActions} from "../../store/school.state"
import {notificationActions} from "../../store/notification.state"
import {defaultSettings, PasswordInput} from "antd-password-input-strength"
import Title from "antd/es/typography/Title";
import PrivacyModal from "../modals/privacy.modal";


interface LoginData {
    readonly email: string
    readonly password: string
}

interface RegisterData {
    readonly newEmail: string
    readonly newPassword: string
    readonly privacy: boolean
}

interface LoginResponse {
    readonly user: IUser
    readonly token: string
}

const LoginGuardPage = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const [searchParams] = useSearchParams()

    const [loginForm] = Form.useForm()
    const [registerForm] = Form.useForm()

    const login = ({email, password}: LoginData) => {
        doLogin(email, password)
    }

    const register = ({newEmail, newPassword}: RegisterData) => {
        const data = {
            email: newEmail,
            password: newPassword
        }

        API.post("user/register", data).then(function (res) {
            localStorage.setItem('token', res.data)
            API.post("user/confirm", {code: 'todo'} //TODO per confirmCode
            ).then(function () {
                doLogin(data.email, data.password)
            })
        })
    }

    const doLogin = (email: string, password: string) => {
        const redirect = searchParams.get('redirect')
        if (redirect) {
            savePastNavigation()
        }

        API.post("user/login", {
            email,
            password
        }).then((data: AxiosResponse<LoginResponse>) => {
            localStorage.setItem('token', data.data.token)

            const user = data.data.user
            dispatch(loginActions.login(user))

            if (user.initialized && user.schoolId) {
                API.get("school", {
                    params: {
                        id: user.schoolId
                    }
                }).then((dataSchool: AxiosResponse<ISchool>) => {
                    dispatch(notificationActions.success("Benvenuto " + dataSchool.data.schoolName))
                    dispatch(schoolActions.save(dataSchool.data))
                })
            }
            if (redirect) {
                restorePastNavigation()
            } else {
                navigate('/home')
            }
        })
    }

    const savePastNavigation = () => {
        sessionStorage.setItem('beforeLogin', location.state?.from?.pathname)
    }

    const restorePastNavigation = () => {
        const beforeLogin = sessionStorage.getItem('beforeLogin')
        sessionStorage.removeItem('beforeLogin')

        const beforeLoginUrl = beforeLogin !== 'undefined' ? beforeLogin : undefined
        navigate(beforeLoginUrl ?? location.state?.from ?? '/home')
    }

    const goToRestorePassword = () => {
        navigate("/reset-password-request")
    }

    const trimLoginEmailOnChange = (inputText: string) => {
        loginForm.setFieldValue('email', inputText.trim())
        loginForm.validateFields(['email'])
    }

    const trimRegisterEmailOnChange = (inputText: string) => {
        registerForm.setFieldValue('newEmail', inputText.trim())
        registerForm.validateFields(['newEmail'])
    }

    const showModal = () => {
        setIsModalOpen(true)
    }

    const closeModal = () => {
        setIsModalOpen(false)
    }

    return (
        <>
            <Row>
                <Col xs={24} md={10}>
                    <h3>Accedi</h3>
                    <Form
                        form={loginForm}
                        layout="vertical"
                        size="middle"
                        initialValues={{remember: false}}
                        onFinish={login}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{required: true, message: 'Email obbligatoria'}, {
                                type: 'email',
                                message: "L'email non è valida"
                            }]}
                        >
                            <Input onChange={(e) => trimLoginEmailOnChange(e.target.value)}/>
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{required: true, message: 'Password obbligatoria'}]}
                        >
                            <Input.Password/>
                        </Form.Item>

                        <Button type="link" style={{padding: 0}} onClick={goToRestorePassword}>Password
                            dimenticata</Button>


                        {/*<Form.Item name="remember" valuePropName="checked">*/}
                        {/*    <Checkbox>Remember me</Checkbox>*/}
                        {/*</Form.Item>*/}

                        <Form.Item style={{marginTop: "70px"}}>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                Entra
                            </Button>
                        </Form.Item>
                        <Title level={5} style={{marginTop: '10px'}}>Abbiamo aggiornato i nostri sistemi di sicurezza.
                            Nel caso non riusciate a fare l'accesso con la vostra vecchia PASSWORD, eseguite la
                            procedura di "Password dimenticata" per creare delle nuove credenziali.</Title>
                    </Form>
                </Col>
                <Col xs={24} md={4}>
                    <h3 style={{textAlign: "center"}}>Oppure</h3>
                </Col>
                <Col xs={24} md={10}>
                    <h3>Registrati</h3>
                    <Form
                        form={registerForm}
                        layout="vertical"
                        size="middle"
                        onFinish={register}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Email"
                            name="newEmail"
                            rules={[{required: true, message: 'Email obbligatoria'}, {
                                type: 'email',
                                message: "L'email non è valida"
                            }]}
                        >
                            <Input onChange={(e) => trimRegisterEmailOnChange(e.target.value)}/>
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="newPassword"
                            rules={[{required: true, message: 'Password obbligatoria'}, {
                                min: 4,
                                message: 'Password troppo corta'
                            }]}
                        >
                            <PasswordInput settings={{
                                ...defaultSettings,
                                alwaysVisible: true,
                                height: 5
                            }}/>
                        </Form.Item>

                        <Form.Item
                            label="Conferma password"
                            name="confirmPassword"
                            rules={[({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject(new Error('Le due password non sono uguali'))
                                }
                            })
                            ]}
                        >
                            <Input.Password/>
                        </Form.Item>

                        <Form.Item style={{marginTop: '20px'}} name="privacy" valuePropName="checked"
                                   rules={[{
                                       required: true,
                                       transform: value => (value || undefined),
                                       type: 'boolean',
                                       message: "E' necessario accettare le condizioni di privacy."
                                   }]}
                        >
                            <Checkbox>
                                Iscrivendo gli atleti accetto le
                                <Button type="link" style={{paddingLeft: '4px', paddingBottom: 0, paddingTop: 0}} onClick={showModal}>condizioni di
                                    privacy.</Button>
                            </Checkbox>
                        </Form.Item>

                        <Form.Item style={{marginTop: "70px"}}>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                Registrati
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <PrivacyModal isOpen={isModalOpen} onCloseModal={closeModal}></PrivacyModal>
        </>
    )
}

export default LoginGuardPage