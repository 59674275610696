import {Button, Menu} from "antd";
import "./menu.component.css"
import {loginActions} from "../../store/login.state";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ItemType, SubMenuType} from "antd/es/menu/hooks/useItems";
import {RootState} from "../../store";
import {
    ControlOutlined,
    HomeOutlined,
    LoginOutlined,
    LogoutOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    QuestionCircleOutlined,
    SettingOutlined,
    UnorderedListOutlined,
    UserOutlined,
    UserSwitchOutlined
} from "@ant-design/icons";
import {schoolActions} from "../../store/school.state";
import HelpModal from "../modals/help.modal";


const MenuComponent = () => {
    const [menuItems, setMenuItems] = useState([] as ItemType[]);

    const [isMobileMode, setIsMobileMode] = useState(false)
    const [collapsed, setCollapsed] = useState(true)
    const [isHelpModalOpen, setIsHelpModalOpen] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const isLogged = useSelector((state: RootState) => {
        return state.login.isLogged
    })

    const school = useSelector((state: RootState) => state.school)

    useEffect(() => {
        return () => setIsHelpModalOpen(false);
    }, []);

    useEffect(() => {
        const media = window.matchMedia('(max-width: 576px)');
        if (media.matches !== isMobileMode) {
            setIsMobileMode(media.matches);
        }
        const listener = () => setIsMobileMode(media.matches);
        window.addEventListener("resize", listener);
        return () => window.removeEventListener("resize", listener);
    }, [isMobileMode]);

    useEffect(() => {
        let leftSideButtons: ItemType[] = [{
            key: 'home',
            icon: <HomeOutlined/>,
            label: <span className="nav-text">Home</span>,
            onClick: () => navigateTo('/home')
        }]

        if (school.schoolName) {
            leftSideButtons = [
                ...leftSideButtons,
                {
                    key: 'subscriptions',
                    icon: <UnorderedListOutlined/>,
                    label: <span className="nav-text">Iscrizioni</span>,
                    onClick: () => navigateTo('/subscriptions')
                }
            ]
        }

        const userSubMenu: SubMenuType = {
            key: 'userSubMenu',
            label: school?.schoolName,
            icon: <UserOutlined/>,
            children: [
                {
                    key: 'changePassword',
                    icon: <UserSwitchOutlined/>,
                    label: <span className="nav-text">Modifica password</span>,
                    onClick: () => navigateTo('/change-password')
                },
                {
                    key: 'logout',
                    icon: <LogoutOutlined/>,
                    label: <span className="nav-text">Esci</span>,
                    onClick: logout
                }
            ]
        }

        if (school.schoolName) {
            userSubMenu.children = [
                {
                    key: 'editSchool',
                    icon: <ControlOutlined/>,
                    label: <span className="nav-text">Dati associazione</span>,
                    onClick: () => navigateTo('/school')
                },
                {
                    key: 'editInfo',
                    icon: <SettingOutlined/>,
                    label: <span className="nav-text">Impostazioni accesso</span>,
                    onClick: () => navigateTo('/school-info')
                },
                ...userSubMenu.children
            ]
        }

        const userButton: ItemType = isLogged ? userSubMenu : {
            key: 'login',
            icon: <LoginOutlined/>,
            label: <span className="nav-text">Accedi</span>,
            onClick: login
        }

        const rightSideButtons = [
            {
                key: 'help',
                icon: <QuestionCircleOutlined/>,
                label: <span className="nav-text">Aiuto</span>,
                className: 'btn-left',
                onClick: showHelpModal
            },
            userButton
        ]

        const definitiveMenuItems: ItemType[] = [
            ...leftSideButtons,
            ...rightSideButtons
        ]

        setMenuItems(definitiveMenuItems)
    }, [isLogged, school.schoolName])

    const showHelpModal = () => {
        setCollapsed(true)
        setIsHelpModalOpen(true)
    }

    const closeHelpModal = () => {
        setIsHelpModalOpen(false)
    }

    const navigateTo = (path: string) => {
        setCollapsed(true)

        navigate(path)
    }

    const login = () => {
        setCollapsed(true)

        navigate("/login")
    }

    const logout = () => {
        setCollapsed(true)

        dispatch(loginActions.logout())
        dispatch(schoolActions.reset())
        goToHome()
    }

    const goToHome = () => {
        navigateTo('/home')
    }

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    }

    return <>
        {
            isMobileMode ? <>
                    <Button type="primary" onClick={toggleCollapsed} style={{marginBottom: 16, marginLeft: 16}}>
                        {collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
                    </Button>
                    {!collapsed && <Menu
                        mode="inline" items={menuItems}/>}

                </>
                : <Menu
                    mode="horizontal" items={menuItems}/>
        }

        <HelpModal key="menu-help-modal" isHelpModalOpen={isHelpModalOpen} closeHelpModal={closeHelpModal}></HelpModal>
    </>
}

export default MenuComponent