import {Button, Col, Form, Input, Row} from "antd"
import React, {useEffect, useState} from "react"
import API from "../../../api"
import './reset-password.page.css'
import Title from "antd/es/typography/Title"
import {useNavigate, useSearchParams} from "react-router-dom"
import {defaultSettings, PasswordInput} from "antd-password-input-strength"
import {notificationActions} from "../../../store/notification.state"
import {useDispatch} from "react-redux"

interface ResetPassword {
    readonly newPassword: string
    readonly confirmPassword: string
}

const ResetPasswordPage = () => {
    const [validKey, setValidKey] = useState<boolean | undefined>(undefined)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()

    useEffect(() => {
        API.post("user/verify-reset-key", {
            email: searchParams.get('email'),
            key: searchParams.get('key')
        }).then(() => {
            setValidKey(true)
        }).catch(() => setValidKey(false))
    }, [])


    const reset = ({newPassword}: ResetPassword) => {
        API.post("user/reset-password", {
            email: searchParams.get('email'),
            newPassword

        }).then(() => {
            dispatch(notificationActions.success("Salvataggio avvenuto con successo"))

            navigate('/login')
        })
    }
    return (
        <>
            {validKey === undefined && <></>}
            {validKey === false ?
                <Row style={{display: "flex", justifyContent: 'center'}}>
                    <Col xs={24} md={10}>
                        <Title level={4} style={{marginBottom: '20px', textAlign: 'center'}}>Link per reset password non
                            valido.</Title>
                    </Col>
                </Row>
                :
                <>
                    <Title level={4} style={{marginBottom: '20px', textAlign: 'center'}}>E' possibile cambiare la
                        propria
                        password.</Title>
                    <Row style={{display: "flex", justifyContent: 'center'}}>
                        <Col xs={24} md={10}>
                            <Form
                                layout="vertical"
                                size="middle"
                                initialValues={{remember: false}}
                                onFinish={reset}
                                autoComplete="off"
                            >
                                <Form.Item
                                    label="Nuova password"
                                    name="newPassword"
                                    rules={[{required: true, message: 'Nuova password obbligatoria'}]}
                                >
                                    <PasswordInput settings={{
                                        ...defaultSettings,
                                        alwaysVisible: true,
                                        height: 5
                                    }}/>
                                </Form.Item>

                                <Form.Item
                                    label="Conferma password"
                                    name="confirmPassword"
                                    rules={[({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('newPassword') === value) {
                                                return Promise.resolve()
                                            }
                                            return Promise.reject(new Error('Le due password non sono uguali.'))
                                        }
                                    })
                                    ]}
                                >
                                    <Input.Password/>
                                </Form.Item>
                                <Form.Item style={{marginTop: "70px"}}>
                                    <Button type="primary" htmlType="submit" className="login-form-button">
                                        Salva
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </>
            }
        </>
    )
}

export default ResetPasswordPage