import {Button, Form, FormInstance, Input} from "antd"
import React, {useState} from "react"
import CodiceFiscale from "codice-fiscale-js"
import {fiscalCodeFormItemValidator} from "../../util/fiscalCode.validator"
import CompetitorChooserModal from "../modals/competitor/competitor-chooser.modal";
import {ICompetitor} from "../../model/competitor.model";
import {SecondStepData} from "../../page/subscriptions/new/second-step.page";

interface Props {
    readonly id: number
    readonly restField: { fieldKey?: number | undefined }
    readonly bmillesimo?: boolean
    readonly form: FormInstance<SecondStepData>
}

const CompetitorComponent = (props: Props) => {
    const [age, setAge] = useState<number | undefined>(undefined)
    const [isModalOpen, setIsModalOpen] = useState(false)

    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleChoosing = (competitor: ICompetitor) => {
        props.form.setFieldValue(["competitors", props.id, "fiscalCode"], competitor.fiscalCode)
        props.form.setFieldValue(["competitors", props.id, "name"], competitor.name)
        props.form.setFieldValue(["competitors", props.id, "surname"], competitor.surname)

        handleFiscalCodeChange(competitor.fiscalCode)

        closeModal()
    }

    const closeModal = () => {
        setIsModalOpen(false)
    }

    const handleFiscalCodeChange = (fiscalCode: string | undefined) => {
        if(fiscalCode !== undefined){
            props.form.setFieldValue(["competitors", props.id, "fiscalCode"], fiscalCode.trim())
            props.form.validateFields(["competitors", props.id, "fiscalCode"])

            if (fiscalCode.trim().length > 0 && CodiceFiscale.check(fiscalCode)) {
                const now = new Date()
                if (props.bmillesimo) {
                    setAge(now.getFullYear() - CodiceFiscale.computeInverse(fiscalCode).year)
                } else {
                    const info = CodiceFiscale.computeInverse(fiscalCode)
                    const birthDay = new Date(info.year, info.month - 1, info.day)
                    const timeDiff = Math.abs(Date.now() - birthDay.getTime());
                    const tempAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
                    setAge(tempAge)
                }
            } else {
                setAge(undefined)
            }
        } else {
            setAge(undefined)
        }

    }

    return (
        <>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <p>Atleta {props.id + 1}</p>
                {age && <p><strong>Età {age}</strong></p>}
                <Button style={{marginTop: "auto", marginBottom: "auto"}} onClick={showModal}>Cerca</Button>
            </div>
            <Form.Item
                {...props.restField}
                label="Codice fiscale"
                name={[props.id, "fiscalCode"]}
                rules={[{required: true, message: 'Codice fiscale obbligatorio'}, fiscalCodeFormItemValidator]}
            >
                <Input onChange={event => handleFiscalCodeChange(event.target.value)}/>
            </Form.Item>
            <Form.Item
                {...props.restField}
                label="Nome"
                name={[props.id, "name"]}
                rules={[{required: true, message: 'Nome obbligatorio'}]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                {...props.restField}
                label="Cognome"
                name={[props.id, "surname"]}
                rules={[{required: true, message: 'Cognome obbligatorio'}]}
            >
                <Input/>
            </Form.Item>
            <CompetitorChooserModal
                onChoosing={handleChoosing}
                isModalOpen={isModalOpen}
                onClose={closeModal}/>
        </>
    )
}

export default CompetitorComponent