import {Button, Form, Input} from "antd"
import React from "react"
import {useNavigate} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {ISchool} from "../../model/school.model"
import {schoolActions} from "../../store/school.state"
import {fiscalCodeFormItemValidator} from "../../util/fiscalCode.validator"
import API from "../../api"
import {RootState} from "../../store";
import {notificationActions} from "../../store/notification.state";

const EditSchoolPage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [form] = Form.useForm()

    const school = useSelector((state: RootState) => state.school)

    const save = (data: ISchool) => {
        API.put("school", {
            id: school.id,
            name: data.schoolName,
            fiscalCode: data.schoolFiscalCode,
            email: data.schoolEmail,
            phone: data.schoolPhone,
            zone: data.schoolZone,
            personName: data.contactPersonName,
            personFiscalCode: data.contactPersonFiscalCode,
            personEmail: data.contactPersonEmail,
            personPhone: data.contactPersonPhone
        }).then(() => {
            dispatch(notificationActions.success("Salvataggio avvenuto con successo"))
            dispatch(schoolActions.updateSchool(data))

            navigate('/home')
        })
    }

    const trimOnChange = (field: string, inputText: string) => {
        form.setFieldValue(field, inputText.trim())
        form.validateFields([field])
    }

    return (
        <>
            <Form
                layout="vertical"
                size="middle"
                name="registrationForm"
                onFinish={save}
                initialValues={school}
                form={form}
            >
                <fieldset>
                    <legend>Dati dell'associazione</legend>
                    <Form.Item
                        label="Nome associazione"
                        name="schoolName"
                        rules={[{required: true, message: 'Nome associazione obbligatoria'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="Codice fiscale associazione"
                        name="schoolFiscalCode"
                        rules={[{
                            required: true,
                            message: 'Codice fiscale associazione obbligatorio'
                        }]}
                    >
                        <Input maxLength={20} onChange={(e) => trimOnChange('schoolFiscalCode', e.target.value)}/>
                    </Form.Item>
                    <Form.Item
                        style={{marginBottom: 0}}
                        label="Email associazione"
                        name="schoolEmail"
                        rules={[{required: true, message: 'Email associazione obbligatoria'}, {
                            type: 'email',
                            message: "L'email non è valida"
                        }]}
                    >
                        <Input onChange={(e) => trimOnChange('schoolEmail', e.target.value)}/>
                    </Form.Item>
                    <p style={{marginTop: 0, marginBottom: "24px"}}>N.B.: Non può esistere due volte la stessa
                        associazione. Prima di
                        inserire o modificare l'email controllare che non sia stata già utilizzata.</p>
                    <Form.Item
                        label="Telefono associazione"
                        name="schoolPhone"
                        rules={[{required: true, message: 'Telefono associazione obbligatorio'}]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Regione associazione"
                        name="schoolZone"
                        rules={[{required: true, message: 'Regione associazione obbligatoria'}]}
                    >
                        <Input/>
                    </Form.Item>
                </fieldset>
                <fieldset style={{marginTop: "30px"}}>
                    <legend>Dati del referente</legend>
                    <Form.Item
                        label="Nome referente"
                        name="contactPersonName"
                        rules={[{required: true, message: 'Nome referente obbligatorio'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="Codice fiscale referente"
                        name="contactPersonFiscalCode"
                        rules={[{
                            required: true,
                            message: 'Codice fiscale referente obbligatorio'
                        }, fiscalCodeFormItemValidator]}
                    >
                        <Input maxLength={20} onChange={(e) => trimOnChange('contactPersonFiscalCode', e.target.value)}/>
                    </Form.Item>
                    <Form.Item
                        label="Email referente"
                        name="contactPersonEmail"
                        rules={[{required: true, message: 'Email referente obbligatoria'}, {
                            type: 'email',
                            message: "L'email non è valida"
                        }]}
                    >
                        <Input onChange={(e) => trimOnChange('contactPersonEmail', e.target.value)}/>
                    </Form.Item>
                    <Form.Item
                        label="Telefono referente"
                        name="contactPersonPhone"
                        rules={[{required: true, message: 'Telefono referente obbligatorio'}]}
                    >
                        <Input />
                    </Form.Item>
                </fieldset>
                <div style={{marginTop: "10px", display: "flex", justifyContent: "end"}}>
                    <Button type="primary" htmlType="submit">
                        Salva
                    </Button>
                </div>
            </Form>
        </>
    )
}

export default EditSchoolPage