import {Button, Form, Input} from "antd"
import React from "react"
import {useNavigate} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {schoolActions} from "../../store/school.state"
import API from "../../api"
import {RootState} from "../../store";
import {notificationActions} from "../../store/notification.state";
import {loginActions} from "../../store/login.state";
import Title from "antd/es/typography/Title";
import {IUser} from "../../model/user.model";

const EditSchoolInfoPage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [form] = Form.useForm()

    const {user} = useSelector((state: RootState) => state.login)

    const save = (data: IUser) => {
        API.put("user/email", {
            id: user!.id,
            email: data.email,
        }).then(() => {
            dispatch(notificationActions.success("Salvataggio avvenuto con successo"))
            dispatch(loginActions.logout())
            dispatch(schoolActions.reset())

            navigate('/login')
        })
    }

    const trimOnChange = (field: string, inputText: string) => {
        form.setFieldValue(field, inputText.trim())
        form.validateFields([field])
    }

    return (
        <>
            <Title level={4}>Per applicare le modifiche sarà necessario rifare l'accesso.</Title>
            <Form
                layout="vertical"
                size="middle"
                name="registrationForm"
                onFinish={save}
                initialValues={user}
                form={form}
            >
                <Form.Item
                    style={{marginBottom: 0}}
                    label="Email"
                    name="email"
                    rules={[{required: true, message: 'Email obbligatoria'}, {
                        type: 'email',
                        message: "L'email non è valida"
                    }]}
                >
                    <Input onChange={(e) => trimOnChange('email', e.target.value)}/>
                </Form.Item>
                <div style={{marginTop: "10px", display: "flex", justifyContent: "end"}}>
                    <Button type="primary" htmlType="submit">
                        Salva
                    </Button>
                </div>
            </Form>
        </>
    )
}

export default EditSchoolInfoPage