import {Button, Col, Image, Row} from "antd";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import API from "../../api";
import {AxiosResponse} from "axios";
import {IEvent} from "../../model/event.model";
import {formatterDate} from "../../util/date.formatter";

const CompetitionDetailPage = () => {
    const [event, setEvent] = useState<IEvent | undefined>(undefined)

    const navigate = useNavigate()

    const params = useParams()

    useEffect(() => {
        API.get("event", {
            params: {
                eventId: params.eventId,
            }
        }).then((response: AxiosResponse<IEvent>) => {
            setEvent(response.data)
        })
    }, [params.eventId])

    const navigateTo = () => {
        navigate('./subscription/first-step')
    }

    const backToHome = () => {
        navigate('/')
    }

    return (
        <>
            {event && (
                <>
                    <Row style={{textAlign: "center"}}>
                        <Col span={24}>
                            <h1 style={{
                                textAlign: "center",
                                fontSize: "30px",
                                marginBottom: "30px"
                            }}>{formatterDate(event.date)} - {event.description}</h1>
                        </Col>
                        {!event.active && <Col span={24}>
                            <h2>Iscrizioni chiuse</h2>
                        </Col>
                        }
                        <Col span={24}>
                            <div className="detail buttons-container">
                                <Button type="default" onClick={backToHome}>
                                    Torna alla home
                                </Button>
                                {event.active ? <Button type="primary" onClick={navigateTo}>
                                    Vai alle iscrizioni online
                                </Button> : <></>
                                }
                            </div>
                            <Image
                                style={{maxWidth: "800px"}}
                                src={event.bigUrl}
                            />
                        </Col>
                    </Row>
                </>
            )
            }
        </>
    )
}

export default CompetitionDetailPage