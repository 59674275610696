import {Col, Form, Input, Modal, Row, Table} from "antd"
import React, {useEffect, useState} from "react"
import {ICompetitor} from "../../../model/competitor.model"
import API from "../../../api"
import {useSelector} from "react-redux"
import {RootState} from "../../../store"
import Column from "antd/es/table/Column"
import './competitor-chooser.modal.css'
import {pipe} from "fp-ts/function"
import {debounceTime, distinctUntilChanged, from, Subject} from "rxjs"
import * as OBS from "fp-ts-rxjs/Observable"

interface CompetitorChooserModalProps {
    readonly isModalOpen: boolean
    readonly onChoosing: (competitor: ICompetitor) => void
    readonly onClose: () => void
}

interface CompetitorChooserFilters {
    readonly search?: string
}

const CompetitorChooserModal = (props: CompetitorChooserModalProps) => {
    const [loading, setLoading] = useState(false)
    const [competitors, setCompetitors] = useState([] as ReadonlyArray<ICompetitor>)
    const [filters, setFilters] = useState({} as CompetitorChooserFilters)

    const [form] = Form.useForm()

    const school = useSelector((state: RootState) => state.school)

    const [onSearch$] = useState(() => new Subject());

    useEffect(() => {
        const subscription = onSearch$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
        ).subscribe((value) => setFilters({search: value as string}));

        return () => subscription.unsubscribe()
    }, [])

    useEffect(() => {
        if(props.isModalOpen){
            setLoading(true)
            pipe(
                from(
                    API.get("competitor/all", {
                        params: {
                            schoolId: school.id,
                            search: filters.search
                        }
                    })
                ),
                OBS.map((res): ICompetitor[] => res.data)
            ).subscribe(value => {
                setCompetitors(value)
                setLoading(false)
            })
        }
    }, [filters.search, props.isModalOpen])

    const onSearchChange = (event: any) => onSearch$.next(event.target.value)

    return (
        <>
            <Modal title="Scegli atleta" open={props.isModalOpen} onCancel={props.onClose} footer={null}>
                <Form
                    form={form}
                    layout="vertical"
                    size="middle"
                    name="filtersForm"
                >
                    <Row gutter={100} justify="space-between">
                        <Col xs={24} md={12}>
                            <Form.Item
                                label="Ricerca"
                                key={"search"}
                                name="search"
                            >
                                <Input type={"text"} onChange={(value) => onSearchChange(value)}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Table
                    id="competitorsTable"
                    loading={loading}
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                props.onChoosing(record)
                            }
                        }
                    }}
                    scroll={{x: "max-content"}}
                    dataSource={competitors}
                    rowClassName="cursor-pointer"
                    rowKey={(record) => record.id + ' ' + Math.random()}
                    pagination={{position: ['bottomCenter']}}>
                    <Column title="Codice fiscale" dataIndex="fiscalCode" key="fiscalCode"/>
                    <Column title="Nome" dataIndex="name" key="name"/>
                    <Column title="Cognome" dataIndex="surname" key="surname"/>
                </Table>
            </Modal>
        </>
    )
}

export default CompetitorChooserModal
